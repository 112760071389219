<template>
  <div>
    <div class="grid" :key="key">
      <v-sheet>
        <v-card v-if="serviceUnit.data" class="pa-4 border">
          {{ t['unit_info'] }}
          <DevInput
              :text="t['registration_number']"
              class="mt-3"
              v-model="serviceUnit.data[0].number"
              disabled
          />
          <DevInput
              :text="t['product_name']"
              v-model="serviceUnit.data[0].service.name"
              disabled
          />
          <DevInput
              :text="t['address']"
              v-model="serviceUnit.data[0].location.name"
              disabled
              hide-details
          />
        </v-card>
        <v-card class="pa-4 mt-4 border">
          <DevImageBlock :text="t['images']" :images="images" :on-delete="deleteImage"/>
          <v-btn @click="loadImage('image')" class="second">
            {{ t['add'] }}
          </v-btn>
        </v-card>
        <v-card class="pa-4 mt-4 border">
          {{ t['files'] }}
          <v-row no-gutters>
            <div v-for="(file, f) in files.length" :key="f" class="rel mr-2 mb-2">
              <div class="file">
                <iframe
                    :src="apiDomain + '/' + files[file - 1]"
                    width="100"
                    height="130"
                    scrolling="no"
                    style="overflow: hidden;"
                />
              </div>
              <v-hover v-slot="{ hover }">
                <v-btn @click="delFile(file)" text width="90" height="120">
                  <v-icon v-if="hover" class="red--text" size="30">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-hover>
            </div>
            <v-btn @click="loadImage('doc')" class="second mt-8">
              <v-icon class="ml-n2 mr-2">mdi-plus</v-icon>
              {{ t['add'] }}
            </v-btn>
          </v-row>
        </v-card>
      </v-sheet>
      <v-sheet>
        <v-card class="pa-4 border">
          <v-form ref="form" lazy-validation>
            <DevInput
                :text="t['title']"
                v-model="name"
                :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
                :text="t['note']"
                v-model="note"
                text-area
                hide-details
            />
          </v-form>
        </v-card>
        <v-card v-if="serviceControl.data" class="mt-4 pa-4 border">
          {{ t['control'] }}
          <div v-for="(cnt, c) in JSON.parse(serviceControl.data)"
               :key="c">
            <v-checkbox
                v-model="control"
                :label="cnt[$route.params.language]"
                :value="cnt[$route.params.language]"
                hide-details
            ></v-checkbox>
          </div>
        </v-card>
      </v-sheet>
      <div>
        <v-btn @click="saveItem" class="primary">
          {{ serviceAction.id ? t['save'] : t['create'] }}
        </v-btn>
      </div>
    </div>
    <input
        type="file"
        id="file"
        ref="file"
        accept=".JPG, .PNG, .SVG, .GIF, .WebP"
        style="display: none"
        v-on:change="handleFileUpload()"
    />
    <input
        type="file"
        id="doc"
        ref="doc"
        accept=".PDF"
        style="display: none"
        v-on:change="handleFileUpload()"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "PagesServicesUnitInspectionEdit",
  components: {DevImageBlock, DevInput},
  data: () => ({
    images: [],
    files: [],
    name: '',
    note: '',
    control: [],
    controlList: {},
    deleted_files: [],
    new_files: [],
    key: 0,
    click_save: false,
    load_file: '',
    field: '',
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters("serviceAction", ["serviceActionList", "serviceAction"]),
    ...mapGetters("serviceControl", ["serviceControl"]),
    ...mapGetters("service", ["serviceUnit"]),
    ...mapGetters(["apiDomain", "file"])
  },
  watch: {
    load_file() {
      this.submitFile()
    },
    serviceAction() {
      let temp = JSON.parse(this.serviceAction.data)
      this.images = temp.images
      this.files = temp.files
      this.name = temp.name
      this.note = temp.note
      this.control = temp.controls
      this.key++
    }
  },
  created() {
    this.onServiceControl(this.$route.params['control'])
    if (this.$route.params.note) {
      this.onServiceAction(this.$route.params.note)
    }
  },
  beforeDestroy() {
    this.closeItem()
  },
  methods: {
    ...mapActions("serviceControl", ["onServiceControl"]),
    ...mapActions(["destroyFile", "uploadFile"]),
    ...mapActions("serviceAction", ["onServiceActionCreate", "onServiceAction", "onServiceActionSave"]),

    closeItem() {
      console.log('закрываем окно')
      if (this.click_save) {
        console.log('нажата кнопка сохранения')
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        console.log('нажата кнопка выхода')
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_image = []
      this.new_images = []
      this.click_save = false
    },

    async saveItem() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        let temp = {
          service_control_id: this.serviceControl.id,
          service_id: this.serviceUnit.data[0].service.id,
          data: {
            images: this.images,
            files: this.files,
            controls: this.control,
            name: this.name,
            note: this.note
          }
        }
        temp.data = JSON.stringify(temp.data)
        if (this.serviceAction.id) {
          await this.onServiceActionSave({id: this.serviceAction.id, data: temp}).then(() => {
            this.$router.push({name: 'notes'})
          })
        } else {
          await this.onServiceActionCreate(temp).then(() => {
            this.$router.push({name: 'notes'})
          })
        }

      }
    },

    delFile(id) {
      this.deleted_files.push(this.files[id])
      this.files.splice(id - 1, 1)
    },

    deleteImage(id) {
      this.deleted_files.push(this.images[id])
      this.images.splice(id - 1, 1)
    },

    loadImage(field) {
      this.field = field
      this.key++
      if (field === 'doc') {
        this.$refs.doc.click()
      } else {
        this.$refs.file.click()
      }

    },
    handleFileUpload() {
      if (this.field === 'doc') {
        this.load_file = this.$refs.doc.files[0]
      } else {
        this.load_file = this.$refs.file.files[0]
      }
    },
    async submitFile() {
      let formData = new FormData()
      if (this.field === 'doc') {
        formData.append('file', this.load_file)
        await this.uploadFile({
          data: formData,
          use_case: 'file',
        }).then(() => {
          this.new_files.push(this.file.url)
          this.files.push(this.file.url)
          this.key++
        })
      } else {
        formData.append('file', this.load_file)
        await this.uploadFile({
          data: formData,
          use_case: 'cover',
        }).then(() => {
          this.new_files.push(this.file.url)
          this.images.push(this.file.url)
          this.key++
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }
}

.rel {
  position: relative;
}

.file {
  width: 90px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
