<template>
  <PagesServicesUnitInspectionEdit/>
</template>

<script>
import PagesServicesUnitInspectionEdit from "components/pages/services/PagesServicesUnitInspectionEdit";

export default {
  name: "inspection_new",
  components: {PagesServicesUnitInspectionEdit}
}
</script>

<style scoped>

</style>
